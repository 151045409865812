<template>

    <div class="page-content">
        <div class="container-fluid">

            <Breadcrumb
                :pgTitle="$t('nav.pages')"
                :pgIcon="'bx bx-file'"
                :refs="refs"
                :addNew="addNew">
            </Breadcrumb>


            <DataTable
                :refs="refs"
                :colspan=5

                :hasSearchFilter=true
                :hasDateFilter=true
                :hasStatusFilter=true
                :hasLecturerFilter=true

                :hasHashId=true
                :hasMultiSelect=false
                :hasBulkAction=true
                :HasExport=true
                :hasShowEntries=true

                :lblSearch="$t('app.search')"

                :fields="{
                    'id': 'id',
                    'title': 'title',
                    'date': 'date',
                    'status': 'status'
                }"

                :hasImage=false

                :hasTitle=true
                :title="$t('view.page_title')"
                
                :hasDate=true
                :hasStatus=true
                :hasActions=true
                :hasDeleteButton=true

                :actionable=true
                :sorDataTable=true

                v-on:hasAddNewPermission="handlePermissoinChange">
            </DataTable>

        </div>
    </div>

</template>

<script>
export default {
    name: 'List',
    components: {
        Breadcrumb: () => import('@/components/Breadcrumb.vue'),
        DataTable: () => import('@/components/DataTable.vue')
    },
    data(){
        return {    
            //
            addNew: true,
            refs: 'pages',
        }
    },
    watch: {
        //
    },
    mounted() {
        //
    },
    created() {
        //
    },
    methods: {
        handlePermissoinChange(event) {
            this.addNew = event;
        },
    },
}
</script>
